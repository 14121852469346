import React, {forwardRef, memo, useEffect, useState} from "react";
import {classNames} from "@/components/common/Helpers";
import FlipBookPageContent from "@/public_obituary/book/flip-book/FlipBookPageContent";
import useFlip from "@/public_obituary/book/flip-book/hooks/useFlip";
import usePageResize from "@/public_obituary/book/flip-book/hooks/usePageResize";
import usePageFlip from "@/public_obituary/book/flip-book/hooks/usePageFlip";
import {useSelector} from "react-redux";

const appUrl = import.meta.env.VITE_APP_URL;

export default memo(forwardRef(
    function FlipBookPage({obituaryUuid, page, initialPageWidth, pageCount, events}, ref) {
        const [show, setShow] = useState(page === 1);
        const [scale, setScale] = useState({x: 0, y: 0});
        const [pageWidth, setPageWidth] = useState(initialPageWidth?.current);

        const pageFlip = usePageFlip(events);

        const shouldShowPage = (page, activePage) => {
            return page === 1 || (page >= activePage - 2 && page <= activePage + 4 && activePage !== 1);
        };

        useFlip(events, (e) => {
            const currentPage = e.detail;

            if (shouldShowPage(page, currentPage)) {
                setShow(true);
            }
        });

        const {
            obituary,
        } = useSelector(state => state.obituaryReducer);

        const bookInfo = obituary?.book;

        useEffect(() => {
            if (bookInfo?.isUpdating === false) {
                setShow(shouldShowPage(page, pageFlip?.getCurrentPageIndex() + 1));
            }
        }, [bookInfo?.isUpdating]);

        usePageResize(events, (e) => {
            setPageWidth(e.detail);
        });

        useEffect(() => {
            setPageWidth(initialPageWidth?.current);
        }, [initialPageWidth?.current]);

        useEffect(() => {
            const iframeWidth = 8.5 * 96;
            const scale = pageWidth / iframeWidth;
            setScale({x: scale, y: scale});
        }, [pageWidth]);

        const firstPage = page === 1;
        const lastPage = !firstPage && page === pageCount;
        const leftPage = page % 2 === 0;

        return (
            <div
                className={classNames(`page page-${page}`, lastPage && 'page-last')}
                style={{paddingBottom: '129.4%',}}
                ref={ref}
                data-density={(firstPage || lastPage) ? 'hard' : 'soft'}
            >
                <div
                    className="w-full h-0 bg-white relative"
                    style={{paddingBottom: '129.4%'}}
                >
                    <FlipBookPageContent
                        obituaryUuid={obituaryUuid}
                        page={page}
                        pageFlip={pageFlip}
                        events={events}
                        scale={scale}
                        active={show}
                    />

                    {/* BOOK HARDCOVER SHADOWS & HIGHLIGHTS */}
                    {(firstPage || lastPage) && (
                        <div
                            className="pointer-events-none absolute top-0 left-0 h-full w-full"
                        >
                            <img
                                src={`${appUrl}/img/obituary-book/book-highlights.jpg`}
                                className="absolute opacity-50 mix-blend-screen	"
                                style={{
                                    transform: page !== 1 ? 'scale(-1,1) translate3d(0,0,0)' : 'translate3d(0,0,0)',
                                }}
                            />

                            <img
                                src={`${appUrl}/img/obituary-book/book-shadows.jpg`}
                                className="absolute mix-blend-multiply"
                                style={{
                                    transform: page !== 1 ? 'scale(-1,1) translate3d(0,0,0)' : 'translate3d(0,0,0)',
                                }}
                            />
                        </div>
                    )}

                    {/* BOOK SPREAD SHADOWS & HIGHLIGHTS */}
                    {!firstPage && !lastPage && (
                        leftPage ? (
                            <>
                                <div
                                    className="spread-shadow absolute right-0 top-0 h-full w-1/6 opacity-20"
                                    style={{
                                        background: 'linear-gradient(-90deg, rgba(0,0,0,1) 0%, rgba(130,130,130,1) 35%, rgba(255,255,255,0) 100%)',
                                    }}
                                >
                                </div>

                                <div
                                    className="spread-shadow absolute right-0 top-0 h-full w-1/6 opacity-20"
                                    style={{
                                        background: 'linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, rgba(255,255,255,1) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%)',
                                    }}
                                >
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className="spread-shadow absolute left-0 top-0 h-full w-1/6 opacity-20"
                                    style={{
                                        background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(130,130,130,1) 35%, rgba(255,255,255,0) 100%)',
                                    }}
                                >
                                </div>

                                <div
                                    className="spread-shadow absolute left-0 top-0 h-full w-1/6 opacity-20"
                                    style={{
                                        background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, rgba(255,255,255,1) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%)',
                                    }}
                                >
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>
        );
    }
));
