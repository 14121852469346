import React, {useCallback, useEffect, useState} from "react";
import Skeleton from 'react-loading-skeleton'
import {classNames} from "@/components/common/Helpers";
import Iframe from "@/public_obituary/book/flip-book/Iframe";
import {useSelector} from "react-redux";
import GuestbookCTA from "@/public_obituary/book/flip-book/page-content/GuestbookCTA";
import TableOfContents from "@/public_obituary/book/flip-book/page-content/TableOfContents";

const FlipBookPageContent = function ({obituaryUuid, page, events, pageFlip, scale, active = true}) {
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [iframeLoading, setIframeLoading] = useState(true);
    const [showIframe, setShowIframe] = useState(false);
    const [content, setContent] = useState('');
    const [version, setBookVersion] = useState(0);

    const {obituary} = useSelector(state => state.obituaryReducer);
    const {obituaryBookList} = useSelector(state => state.obituaryBookReducer);

    const bookInfo = !Array.isArray(obituaryBookList) ? obituaryBookList : null;

    useEffect(() => {
        if (version === 0 || bookInfo?.isUpdating === false) {
            setBookVersion((prev) => prev + 1);
        }
    }, [!!bookInfo?.isUpdating]);

    useEffect(() => {
        if (!active || !obituaryUuid || !page) return;

        let isMounted = true;

        const fetchContent = async () => {
            try {
                if (version === 0) return;
                const html = await getIframeContent(obituaryUuid, page, version);
                if (isMounted) {
                    setContent(html);
                    setIsContentLoading(false);
                }
            } catch (e) {
                console.error('Failed to load page:', e);
                if (isMounted) {
                    setIsContentLoading(false);
                }
            }
        };

        fetchContent();

        return () => {
            isMounted = false;
        };
    }, [active, obituaryUuid, page, version]);

    const handleLoad = useCallback(() => {
        setIframeLoading(false);
    }, []);

    useEffect(() => {
        if (!iframeLoading && !isContentLoading) {
            // this give render time for skeleton to hide
            setTimeout(() => setShowIframe(true), 500);
        }
    }, [iframeLoading, isContentLoading]);

    const section = bookInfo?.pageSections?.[page - 1] || '';
    const loading = ((bookInfo?.pageCount === 0 && page !== 1) || isContentLoading || iframeLoading) && section !== 'table-of-contents';

    return (
        <div
            style={{
                overflow: 'hidden',
                position: 'relative',
                paddingBottom: '129.4%',
                background: '#fff',
                height: 0,
            }}
        >
            <div
                className={classNames(
                    "text-black w-full h-full absolute overflow-hidden",
                )}
                style={{
                    padding: '15%',
                    visibility: !active || loading ? 'visible' : 'hidden',
                }}
            >
                <div className="overflow-hidden h-full">
                    <Skeleton height={8} count={40} baseColor="#e0e0e0" />
                </div>
            </div>

            {active && obituaryUuid && page && (
                <>
                    <div>
                        {section !== 'table-of-contents' && (
                            <Iframe
                                content={content}
                                scale={scale}
                                onLoad={handleLoad}
                            />
                        )}
                    </div>

                    {section === 'table-of-contents' && bookInfo?.tableOfContentsHtml && (
                        <TableOfContents
                            html={bookInfo.tableOfContentsHtml}
                            pageFlip={pageFlip}
                        />
                    )}

                    {section === 'guestbook-title' && (
                        <GuestbookCTA obituary={obituary} show={showIframe}/>
                    )}
                </>
            )}
        </div>
    );
};

let iframeContentLoading = {};
let iframeContentCache = {};
const getIframeContent = async (obituaryUuid, page, version = "1") => {
    const cache = iframeContentCache;
    const key = `${obituaryUuid}-${version}`;
    if (cache[key]?.[page]) {
        return cache[key][page];
    }

    if (iframeContentLoading[key]?.[page]) {
        return new Promise((resolve, reject) => {
            let interval;
            interval = setInterval(() => {
                if (!iframeContentLoading[key]?.[page]) {
                    clearInterval(interval);
                    resolve(cache[key][page]);
                }
            }, 100);
        });
    }

    try {
        iframeContentLoading[key] = iframeContentLoading[key] || {};
        iframeContentLoading[key][page] = true;

        const srcUrl = `${import.meta.env.VITE_APP_URL}/obituaries/${obituaryUuid}/book/${page}`;
        const response = await fetch(srcUrl);
        const text = await response.text();
        cache[key] = cache[key] || {};
        cache[key][page] = text;
        return text;
    } finally {
        iframeContentLoading[key][page] = false;
    }
};

export default FlipBookPageContent;
